import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export enum SwapState {
  NOT_STARTED,
  WAITING_FOR_APPROVAL,
  WAITING_FOR_SWAP,
  COMPLETE,
  FAILURE,
}

interface TransferButtonProps {
  isValid: boolean;
  isPaused: boolean;
  isLoading: boolean;
  capReached: boolean;
  swapState: SwapState;
}

export function TransferButton(props: TransferButtonProps) {
  if (props.isLoading) {
    return (
      <input
        className="SubmitButton"
        type="submit"
        value="Loading..."
        disabled={true}
      />
    );
  } else if (props.isPaused) {
    return (
      <input
        className="SubmitButton"
        type="submit"
        value="Transfers Paused"
        disabled={true}
      />
    );
  } else if (props.capReached) {
    return (
      <input
        className="SubmitButton"
        type="submit"
        value="Bridge Limit Reached"
        disabled={true}
      />
    );
  } else {
    switch (props.swapState) {
      case SwapState.NOT_STARTED:
        return (
          <input
            className="SubmitButton"
            type="submit"
            value="Transfer"
            disabled={!props.isValid}
          />
        );
      case SwapState.WAITING_FOR_APPROVAL:
        return (
          <>
            <input
              className="StatusButton"
              type="submit"
              value="Waiting for approval"
              disabled={true}
            />
            <div className="ProgressIconTray">
              <FontAwesomeIcon icon={faSpinner} spin={true} size="2x" />
            </div>
          </>
        );
      case SwapState.WAITING_FOR_SWAP:
        return (
          <>
            <input
              className="StatusButton"
              type="submit"
              value="Waiting for swap"
              disabled={true}
            />
            <div className="ProgressIconTray">
              <FontAwesomeIcon icon={faSpinner} spin={true} size="2x" />
            </div>
          </>
        );
      case SwapState.COMPLETE:
        return (
          <input
            className="StatusButton"
            type="submit"
            value="Complete!"
            disabled={true}
          />
        );
      case SwapState.FAILURE:
        return (
          <input
            className="FailureButton"
            type="submit"
            value="Swap Failure"
            disabled={true}
          />
        );
    }
  }
}
