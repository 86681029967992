import React, { FormEvent, useMemo, useState } from 'react';
import { TextInput } from './TextInput';
import {
  snooze,
  validateAmount,
  validateNativeFetchAddress,
  ValidationState,
} from '../utils';
import { EthBridgeContract, EthBridgeStatus } from '../contracts/ethBridge';
import Decimal from 'decimal.js';

import { SwapState, TransferButton } from './TransferButton';
import { NativeBridgeStatus } from '../contracts/nativeBridge';

interface EthToNativeTransferProps {
  ethBridgeStatus?: EthBridgeStatus;
  nativeBridgeStatus?: NativeBridgeStatus;
  bridgeContract: EthBridgeContract;
  account: string;
}

const DEFAULT_AMOUNT = '';
const DEFAULT_NATIVE_ADDRESS = '';

function isCapReached(status?: EthBridgeStatus) {
  if (status === undefined) {
    return true;
  }

  return status.supply.greaterThanOrEqualTo(status.cap);
}

export function EthToNativeTransfer(props: EthToNativeTransferProps) {
  const [swapState, setSwapState] = useState<SwapState>(SwapState.NOT_STARTED);
  const [amount, setAmount] = useState<string>(DEFAULT_AMOUNT);
  const [nativeAddress, setNativeAddress] = useState<string>(
    DEFAULT_NATIVE_ADDRESS
  );

  const numAmount: Decimal = useMemo(() => {
    if (validateAmount(amount) === ValidationState.VALID) {
      return new Decimal(amount).mul('1e18');
    } else {
      return new Decimal('0');
    }
  }, [amount]);

  const formHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    async function resetForm(success: boolean) {
      if (success) {
        setSwapState(SwapState.COMPLETE);
      } else {
        setSwapState(SwapState.FAILURE);
      }

      await snooze(2000);

      // if successful reset the values of the form back to defaults
      if (success) {
        setAmount(DEFAULT_AMOUNT);
        setNativeAddress(DEFAULT_NATIVE_ADDRESS);
      }
      setSwapState(SwapState.NOT_STARTED);
    }

    // send approval
    setSwapState(SwapState.WAITING_FOR_APPROVAL);
    const approvalResult = await props.bridgeContract.approve(
      numAmount,
      props.account
    );
    if (!approvalResult.success) {
      await resetForm(false);
      return;
    }

    // send the swap
    setSwapState(SwapState.WAITING_FOR_SWAP);
    const swapResult = await props.bridgeContract.swap(
      numAmount,
      nativeAddress,
      props.account
    );
    if (!swapResult.success) {
      await resetForm(false);
      return;
    }

    await resetForm(true);
  };

  const validationNativeAddress = validateNativeFetchAddress(nativeAddress);
  let validationAmount = validateAmount(amount);
  let validationAmountText = 'Invalid amount';
  if (
    validationAmount === ValidationState.VALID &&
    props.ethBridgeStatus !== undefined &&
    props.nativeBridgeStatus !== undefined
  ) {
    const nextSupply = props.ethBridgeStatus.supply.add(numAmount);

    if (numAmount.lessThan(props.ethBridgeStatus.swapMin)) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount too small. Min: ${props.ethBridgeStatus.swapMin
        .div('1e18')
        .toString()} FET`;
    } else if (numAmount.greaterThan(props.ethBridgeStatus.swapMax)) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount too large. Max: ${props.ethBridgeStatus.swapMax
        .div('1e18')
        .toString()} FET`;
    } else if (numAmount.greaterThan(props.nativeBridgeStatus.swapMax)) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount too large. Max: ${props.nativeBridgeStatus.swapMax
        .div('1e18')
        .toString()} FET`;
    } else if (
      props.ethBridgeStatus.fetBalance !== undefined &&
      numAmount.greaterThan(props.ethBridgeStatus.fetBalance)
    ) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount larger than available FET balance`;
    } else if (nextSupply.greaterThan(props.ethBridgeStatus.cap)) {
      validationAmount = ValidationState.INVALID;

      if (
        props.ethBridgeStatus.supply.greaterThanOrEqualTo(
          props.ethBridgeStatus.cap
        )
      ) {
        validationAmountText = `The token bridge has reached its current cap, no further transfers possible at this time`;
      } else {
        const remaining = props.ethBridgeStatus.cap.sub(
          props.ethBridgeStatus.supply
        );
        validationAmountText = `Amount would breach the bridge cap. Remaining allowance: ${remaining
          .div('1e18')
          .toString()} FET`;
      }
    } else if (
      numAmount.greaterThan(props.nativeBridgeStatus.reverseAggLimit)
    ) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount is larger than the current bridge limit. Remaining allowance ${props.nativeBridgeStatus.reverseAggLimit
        .div('1e18')
        .toString()}`;
    } else if (numAmount.greaterThan(props.nativeBridgeStatus.supply)) {
      validationAmount = ValidationState.INVALID;
      validationAmountText = `Amount is larger than the current bridge supply. Remaining amount ${props.nativeBridgeStatus.supply
        .div('1e18')
        .toString()}`;
    }
  }

  const isLoading =
    props.ethBridgeStatus === undefined ||
    props.nativeBridgeStatus === undefined;
  const isPaused =
    (props.ethBridgeStatus?.paused ?? true) ||
    (props.nativeBridgeStatus?.paused ?? true);
  const capReached = isCapReached(props.ethBridgeStatus);
  const isValid =
    validationNativeAddress === ValidationState.VALID &&
    validationAmount === ValidationState.VALID &&
    !capReached &&
    !isPaused;

  let transferFee = 0;
  if (
    props.nativeBridgeStatus !== undefined &&
    props.nativeBridgeStatus.fee.greaterThan(0)
  ) {
    transferFee = props.nativeBridgeStatus.fee.dividedBy('1e18').toNumber();
  }

  return (
    <form className="InputForm" noValidate onSubmit={formHandler}>
      <TextInput
        name="Ethereum Address"
        value={props.account}
        readOnly={true}
      />

      <TextInput
        name="Native Address"
        value={nativeAddress}
        onChange={setNativeAddress}
        invalidText="Invalid native address"
        validation={validationNativeAddress}
      />

      <TextInput
        name="Amount"
        value={amount}
        onChange={setAmount}
        invalidText={validationAmountText}
        validation={validationAmount}
      />

      {transferFee > 0 && (
        <TextInput
          name="Transfer Fee"
          value={`${transferFee} FET`}
          readOnly={true}
        />
      )}

      <TransferButton
        isValid={isValid}
        isPaused={isPaused}
        isLoading={isLoading}
        capReached={capReached}
        swapState={swapState}
      />
    </form>
  );
}
