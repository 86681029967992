import Decimal from 'decimal.js';
import { CONFIG } from '../config';
import {
  CosmWasmClient,
  SigningCosmWasmClient,
} from '@cosmjs/cosmwasm-stargate';
import { Coin, GasPrice } from '@cosmjs/stargate';
import { BECH32_PREFIX } from '../ledger';
import { OfflineSigner } from '@cosmjs/proto-signing';

export interface AccountDetails {
  balance: Decimal;
  accountNumber: number;
  sequence: number;
}

async function getSigningCosmWasmClient(
  signer: OfflineSigner
): Promise<SigningCosmWasmClient> {
  return SigningCosmWasmClient.connectWithSigner(CONFIG.fetch.rpcUrl, signer, {
    prefix: BECH32_PREFIX,
    gasPrice: GasPrice.fromString(CONFIG.fetch.gasPrice),
  });
}

export async function queryAccountDetails(
  address: string
): Promise<AccountDetails> {
  const client = await CosmWasmClient.connect(CONFIG.fetch.rpcUrl);
  const accountBalance = await client.getBalance(address, CONFIG.fetch.denom);
  const account = await client.getAccount(address);
  return {
    accountNumber: account?.accountNumber || 0,
    sequence: account?.sequence || 0,
    balance: new Decimal(accountBalance.amount),
  };
}

export async function swap(
  signer: OfflineSigner,
  fromAddress: string,
  ethAddress: string,
  contractAddress: string,
  amount: Coin
): Promise<boolean> {
  try {
    const client = await getSigningCosmWasmClient(signer);
    await client.execute(
      fromAddress,
      contractAddress,
      {
        swap: { destination: ethAddress },
      },
      'auto',
      'fetch.ai bridge Native - ETH swap',
      [amount]
    );
  } catch (e) {
    console.log('Swap error', e);
    return false;
  }
  return true;
}
