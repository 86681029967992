import React, { useEffect, useState } from 'react';
import './App.scss';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import { MetaMaskUnavailable } from './components/MetamaskUnavailable';
import { EthToNativeTransfer } from './components/EthToNativeTransfer';
import { NativeToEthTransfer } from './components/NativeToEthTransfer';
import { MetamaskLocked } from './components/MetamaskLocked';

import logo from './logo.svg';
import { CONFIG, NETWORK, VERSION } from './config';
import { Network, parseNetwork } from './networks';
import { InternalError } from './components/InternalError';
import {
  EthBridgeContract,
  EthBridgeStatus,
  toEthStatusJson,
} from './contracts/ethBridge';
import { WrongNetwork } from './components/WrongNetwork';
import {
  NativeBridgeContract,
  NativeBridgeStatus,
  toNativeStatusJson,
} from './contracts/nativeBridge';
import Decimal from 'decimal.js';
import { StatsCard } from './components/StatsCard';
import { TabSelection, TransferTab } from './components/TabSelection';
import { OfflineSigner } from '@cosmjs/proto-signing';

const LOG_CONTRACT_STATUS = false;

function logNativeContractStatus(status: NativeBridgeStatus) {
  if (LOG_CONTRACT_STATUS) {
    console.log('NATIVE STATUS', toNativeStatusJson(status));
  }
}

function logEthContractStatus(status: EthBridgeStatus) {
  if (LOG_CONTRACT_STATUS) {
    console.log('ETH STATUS', toEthStatusJson(status));
  }
}

type MaybeDecimal = Decimal | undefined;

function minDecimal(...values: MaybeDecimal[]): MaybeDecimal {
  const filtered = values.filter((value) => value !== undefined) as Decimal[];
  if (filtered.length === 0) {
    return undefined;
  }

  const sorted = filtered.sort((lhs: Decimal, rhs: Decimal): number => {
    if (lhs.greaterThan(rhs)) {
      return 1;
    } else if (lhs.lessThan(rhs)) {
      return -1;
    } else {
      return 0;
    }
  });

  return sorted[0];
}

function subDecimal(lhs: MaybeDecimal, rhs: MaybeDecimal): MaybeDecimal {
  if (lhs === undefined && rhs === undefined) {
    return undefined;
  }

  if (lhs === undefined) {
    return rhs;
  }

  if (rhs === undefined) {
    return lhs;
  }

  return lhs.sub(rhs);
}

function clipToZero(value: MaybeDecimal): MaybeDecimal {
  if (value === undefined) {
    return undefined;
  } else if (value.lessThan(0)) {
    return new Decimal(0);
  } else {
    return value;
  }
}

function formatEthAddress(
  web3: Web3,
  value: string | undefined
): string | undefined {
  if (value === undefined) {
    return undefined;
  }

  return web3.utils.toChecksumAddress(value);
}

function App() {
  const [signer, setSigner] = useState<OfflineSigner | undefined>();
  const [nativeBridgeContract] = useState<NativeBridgeContract>(
    new NativeBridgeContract(CONFIG.fetch.contractAddress)
  );
  const [ethBridgeContract, setEthBridgeContract] = useState<
    EthBridgeContract | undefined
  >();
  const [ethBridgeStatus, setEthBridgeStatus] = useState<
    EthBridgeStatus | undefined
  >();
  const [nativeBridgeStatus, setNativeBridgeStatus] = useState<
    NativeBridgeStatus | undefined
  >();
  const [web3, setWeb3] = useState<Web3 | undefined>();
  const [network, setNetwork] = useState<Network>(Network.UNKNOWN);
  const [tab, setTab] = useState<TransferTab>(TransferTab.ETH_TO_NATIVE);
  const [ethAddress, setEthAddress] = useState<string | undefined>();
  const [nativeAddress, setNativeAddress] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      const provider = await detectEthereumProvider();
      // @ts-ignore
      const web3 = new Web3(provider);

      // get the current account
      const accounts = await web3.eth.getAccounts();

      // @ts-ignore
      const chainId: string = window.ethereum.chainId;

      // @ts-ignore
      window.ethereum.on('chainChanged', (_chainId) =>
        window.location.reload()
      );

      // @ts-ignore
      window.ethereum.on('accountsChanged', (accounts: string[]) => {
        setEthAddress(formatEthAddress(web3, accounts[0]));
      });

      setNetwork(parseNetwork(chainId));
      setWeb3(web3);
      setEthAddress(formatEthAddress(web3, accounts[0]));

      const ethBridgeContract = new EthBridgeContract(
        web3,
        CONFIG.eth.bridgeContract,
        CONFIG.eth.tokenContract
      );

      setEthBridgeContract(ethBridgeContract);

      const ethStatus = await ethBridgeContract.getStatus();
      logEthContractStatus(ethStatus);
      setEthBridgeStatus(ethStatus);

      const nativeBridgeContract = new NativeBridgeContract(
        CONFIG.fetch.contractAddress
      );

      const nativeStatus = await nativeBridgeContract.getStatus();
      logNativeContractStatus(nativeStatus);
      setNativeBridgeStatus(nativeStatus);
    })().catch((err) => {
      console.log('Failed to configure metamask', err);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      // query the ETH bridge status
      try {
        if (ethBridgeContract !== undefined) {
          const status = await ethBridgeContract.getStatus(ethAddress);
          logEthContractStatus(status);
          setEthBridgeStatus(status);
        }
      } catch (e) {
        console.log('Unable to query eth bridge status', e);
        setEthBridgeStatus(undefined);
      }

      // query the native bridge status
      try {
        const nextNativeStatus = await nativeBridgeContract.getStatus();
        logNativeContractStatus(nextNativeStatus);
        setNativeBridgeStatus(nextNativeStatus);
      } catch (e) {
        console.log('Unable to query native bridge status', e);
        setNativeBridgeStatus(undefined);
      }
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [ethBridgeContract, ethAddress, nativeBridgeContract]);

  // select the component based on the state
  let component;
  let active = false;
  if (web3 === undefined) {
    component = <MetaMaskUnavailable />;
  } else if (ethAddress === undefined) {
    component = <MetamaskLocked />;
  } else if (NETWORK !== network) {
    component = <WrongNetwork expected={NETWORK} actual={network} />;
  } else if (CONFIG === undefined) {
    component = <InternalError />;
  } else if (ethBridgeContract === undefined) {
    component = null;
  } else {
    active = true;

    switch (tab) {
      case TransferTab.ETH_TO_NATIVE:
        component = (
          <div className="CardContainer">
            <TabSelection tab={tab} setTab={setTab} />

            <EthToNativeTransfer
              account={ethAddress}
              bridgeContract={ethBridgeContract}
              ethBridgeStatus={ethBridgeStatus}
              nativeBridgeStatus={nativeBridgeStatus}
            />
          </div>
        );
        break;
      case TransferTab.NATIVE_TO_ETH:
        component = (
          <div className="CardContainer">
            <TabSelection tab={tab} setTab={setTab} />

            <NativeToEthTransfer
              ethAddress={ethAddress}
              nativeAddress={nativeAddress ?? ''}
              nativeStatus={nativeBridgeStatus}
              ethStatus={ethBridgeStatus}
              setNativeAddress={setNativeAddress}
              setSigner={setSigner}
              signer={signer}
            />
          </div>
        );
        break;
    }
  }

  return (
    <div className="App">
      <div className="Header">
        <img
          src={logo}
          height={46}
          alt="Fetch.ai logo"
          className="HeaderLogo"
        />
      </div>
      <div className="StatsWrapper">
        <h1>Token Bridge</h1>

        {active && (
          <div className="StatsContainer">
            <StatsCard
              title="ERC20 to Native Limit"
              value={clipToZero(
                minDecimal(
                  nativeBridgeStatus?.supply,
                  subDecimal(ethBridgeStatus?.cap, ethBridgeStatus?.supply)
                )
              )}
            />
            <StatsCard
              title="Native to ERC20 Limit"
              value={clipToZero(
                minDecimal(
                  ethBridgeStatus?.supply,
                  subDecimal(
                    nativeBridgeStatus?.cap,
                    nativeBridgeStatus?.supply
                  )
                )
              )}
            />
          </div>
        )}
      </div>

      <div className="Content">
        <div className="Card">{component !== null && component}</div>
      </div>

      <div className="Footer">
        <p>Fetch.ai - 2021 - {VERSION}</p>
      </div>
    </div>
  );
}

export default App;
