import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';
import 'firebase/remote-config';
import { ENABLE_ANALYTICS } from './config';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const firebaseConfig = {
  apiKey: 'AIzaSyA4l6nG1kE1LqxTuzW6yeyYlSfItBu1DQg',
  authDomain: 'fetch-ai-token-bridge-ui.firebaseapp.com',
  projectId: 'fetch-ai-token-bridge-ui',
  storageBucket: 'fetch-ai-token-bridge-ui.appspot.com',
  messagingSenderId: '828203728410',
  appId: '1:828203728410:web:522c80a304c247d58c1ba3',
  measurementId: 'G-GKWS43PVTR',
};

// setup the firebase components if we are monitoring
if (ENABLE_ANALYTICS) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  firebase.performance();
  firebase.remoteConfig();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
