import React from 'react';
import Decimal from 'decimal.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface StatsCardProps {
  title: string;
  value: Decimal | string | undefined;
}

function formatValue(value: Decimal | string | undefined): string | undefined {
  if (value === undefined) {
    return undefined;
  }

  if (typeof value === 'string') {
    return value;
  }

  return `${value.div('1e18').toNumber().toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })} FET`;
}

export function StatsCard(props: StatsCardProps) {
  const value = formatValue(props.value);

  return (
    <div className="StatsCard">
      <div className="StatsName">{props.title}</div>
      <span>
        {value === undefined && (
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="sm"
            style={{ marginLeft: 10 }}
          />
        )}
        {value !== undefined && value}
      </span>
    </div>
  );
}
