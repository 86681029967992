import Web3 from 'web3';

import { AbiItem } from 'web3-utils';
import { Contract } from 'web3-eth-contract';
import Decimal from 'decimal.js';

import bridgeAbi from '../abi/bridge.abi.json';
import erc20Abi from '../abi/erc20.abi.json';
import { MAX_FET_SUPPLY } from '../config';

export interface EthBridgeStatus {
  paused: boolean;
  swapMin: Decimal;
  swapMax: Decimal;
  supply: Decimal;
  cap: Decimal;
  fee: Decimal;
  reverseAggLimit: Decimal;
  reverseAggLimitCap: Decimal;
  fetBalance?: Decimal;
}

interface JsonEthBridgeStatus {
  paused: boolean;
  swapMin: string;
  swapMax: string;
  supply: string;
  cap: string;
  fee: string;
  reverseAggLimit: string;
  reverseAggLimitCap: string;
  fetBalance?: string;
}

export function toEthStatusJson(status: EthBridgeStatus): JsonEthBridgeStatus {
  return {
    paused: status.paused,
    swapMin: status.swapMin.toFixed(0),
    swapMax: status.swapMax.toFixed(0),
    supply: status.supply.toFixed(0),
    cap: status.cap.toFixed(0),
    reverseAggLimit: status.reverseAggLimit.toFixed(0),
    reverseAggLimitCap: status.reverseAggLimitCap.toFixed(0),
    fee: status.fee.toFixed(0),
    fetBalance: status.fetBalance?.toFixed(0),
  };
}

interface TxResult {
  success: boolean;
  tx?: string;
  error?: string;
}

const MIN_AMOUNT = new Decimal(0);
const MAX_AMOUNT = new Decimal(MAX_FET_SUPPLY).mul(new Decimal(10).pow(18));

export function formatFetAmount(value: Decimal): string {
  if (value.lessThan(MIN_AMOUNT) || value.greaterThan(MAX_AMOUNT)) {
    throw new Error('Invalid FET amount');
  }

  return value.toFixed(0);
}

export class EthBridgeContract {
  private web3: Web3;
  private bridgeAddress: string;
  private tokenAddress: string;
  private bridge: Contract;
  private token: Contract;

  constructor(web3: Web3, bridgeAddress: string, tokenAddress: string) {
    this.web3 = web3;
    this.bridgeAddress = bridgeAddress;
    this.tokenAddress = tokenAddress;
    this.bridge = new web3.eth.Contract(bridgeAbi as AbiItem[], bridgeAddress);
    this.token = new web3.eth.Contract(erc20Abi as AbiItem[], tokenAddress);
  }

  async approve(amount: Decimal, from: string): Promise<TxResult> {
    try {
      // determine users current allowance
      const currentAllowance = new Decimal(
        (
          await this.token.methods.allowance(from, this.bridgeAddress).call()
        ).toString()
      );

      // check the current user allowance against that which needs to be approved.
      // only trigger the allowance when required
      if (currentAllowance.lessThan(amount)) {
        const method = this.token.methods.approve(
          this.bridgeAddress,
          formatFetAmount(amount)
        );

        const resp = await method.send({
          from,
        });

        console.log('Approval TX', resp);

        return { success: true, tx: resp.transactionHash };
      }

      return { success: true };
    } catch (e) {
      console.log('Approval Failure', e);
    }

    return { success: false };
  }

  async swap(
    amount: Decimal,
    destination: string,
    from: string
  ): Promise<TxResult> {
    try {
      // define the method call
      const method = this.bridge.methods.swap(
        formatFetAmount(amount),
        destination
      );

      // send the transaction to the network
      const resp = await method.send({
        from,
      });

      console.log('Swap TX', resp);

      return { success: true, tx: resp.transactionHash };
    } catch (e) {
      console.log('Swap Failure', e);
    }

    return { success: false };
  }

  async getStatus(address?: string): Promise<EthBridgeStatus> {
    const swapMin = new Decimal(
      (await this.bridge.methods.getSwapMin().call()).toString()
    );
    const swapMax = new Decimal(
      (await this.bridge.methods.getSwapMax().call()).toString()
    );

    const pausedSince = new Decimal(
      (
        await this.bridge.methods.getPausedSinceBlockPublicApi().call()
      ).toString()
    );
    const pausedRelayerSince = new Decimal(
      (
        await this.bridge.methods.getPausedSinceBlockRelayerApi().call()
      ).toString()
    );

    const supply = new Decimal(
      (await this.bridge.methods.getSupply().call()).toString()
    );
    const cap = new Decimal(
      (await this.bridge.methods.getCap().call()).toString()
    );

    const fee = new Decimal(
      (await this.bridge.methods.getSwapFee().call()).toString()
    );

    const reverseAggLimit = new Decimal(
      (
        await this.bridge.methods.getReverseAggregatedAllowance().call()
      ).toString()
    );

    const reverseAggLimitCap = new Decimal(
      (
        await this.bridge.methods
          .getReverseAggregatedAllowanceApproverCap()
          .call()
      ).toString()
    );

    // check to see if any of the pauses are inplace in the contract
    const currentBlock = new Decimal(await this.web3.eth.getBlockNumber());
    const isPaused = currentBlock.greaterThanOrEqualTo(pausedSince);
    const isRelayerPaused = currentBlock.greaterThanOrEqualTo(
      pausedRelayerSince
    );

    // check the users balance at the same time
    let fetBalance: Decimal | undefined = undefined;
    if (address !== undefined) {
      fetBalance = new Decimal(
        (await this.token.methods.balanceOf(address).call()).toString()
      );
    }

    return {
      fee,
      reverseAggLimit,
      reverseAggLimitCap,
      paused: isPaused || isRelayerPaused,
      swapMin,
      swapMax,
      supply,
      cap,
      fetBalance,
    };
  }
}
