import { Network, parseNetwork } from './networks';

export const MAX_FET_SUPPLY = '1152997575';
export const VERSION = process.env.REACT_APP_VERSION ?? 'v0.0.0-dev1';
export const NETWORK = parseNetwork(process.env.REACT_APP_NETWORK_ID ?? '42');

export interface NetworkConfig {
  eth: {
    bridgeContract: string;
    tokenContract: string;
  };
  fetch: {
    contractAddress: string;
    restUrl: string;
    rpcUrl: string;
    chainId: string;
    denom: string;
    gasPrice: string;
    chainName: string;
    tokenTicker: string;
  };
}

function chooseConfiguration(): NetworkConfig {
  switch (NETWORK) {
    case Network.MAINNET:
      return {
        eth: {
          bridgeContract: '0x947872ad4d95e89E513d7202550A810aC1B626cC',
          tokenContract: '0xaea46A60368A7bD060eec7DF8CBa43b7EF41Ad85',
        },
        fetch: {
          contractAddress: 'fetch1qxxlalvsdjd07p07y3rc5fu6ll8k4tmetpha8n',
          restUrl: 'https://rest-fetchhub.fetch.ai:443',
          rpcUrl: 'https://rpc-fetchhub.fetch.ai:443',
          chainId: 'fetchhub-4',
          denom: 'afet',
          gasPrice: '0afet',
          chainName: 'FetchHub',
          tokenTicker: 'FET',
        },
      };
    case Network.KOVAN:
      return {
        eth: {
          bridgeContract: '0x3373CD8F02F4f0f2Cb00c358A9f925695ba0f063',
          tokenContract: '0xd3B4C4F2c56574a1156513Fed3F163EccC2E736B',
        },
        fetch: {
          contractAddress:
            'fetch1suhgf5svhu4usrurvxzlgn54ksxmn8gljarjtxqnapv8kjnp4nrsa26575',
          restUrl: 'https://rest-dorado.fetch.ai:443',
          rpcUrl: 'https://rpc-dorado.fetch.ai:443',
          chainId: 'dorado-1',
          denom: 'atestfet',
          gasPrice: '1000000000atestfet',
          chainName: 'Dorado',
          tokenTicker: 'TESTFET',
        },
      };
    case Network.UNKNOWN:
    default:
      return {
        eth: {
          bridgeContract: '',
          tokenContract: '',
        },
        fetch: {
          contractAddress: '',
          restUrl: '',
          rpcUrl: '',
          chainId: '',
          denom: '',
          gasPrice: '',
          chainName: '',
          tokenTicker: '',
        },
      };
  }
}

interface SwapConfig {
  maxSingleNativeSwap?: string;
}

export const CONFIG: NetworkConfig = chooseConfiguration();

export const SWAP_CONFIG: SwapConfig = {
  maxSingleNativeSwap: '50000000000000000000000',
};

export const ENABLE_ANALYTICS = process.env.REACT_APP_ENABLE_ANALYTICS === '1';
