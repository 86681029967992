import React from 'react';
import agentLogo from '../agent.png';
import { formatNetwork, Network } from '../networks';

interface WrongNetworkProps {
  expected: Network;
  actual: Network;
}

export function WrongNetwork(props: WrongNetworkProps) {
  return (
    <div className="CardContainer">
      <div className="TextContainer">
        <img src={agentLogo} height={150} alt="Agent Logo" />
        <p>Looks like Metamask is pointing to the wrong network!</p>
        <p>
          We were expecting {formatNetwork(props.expected)} but you seem to be
          pointed to {formatNetwork(props.actual)}
        </p>
      </div>
    </div>
  );
}
