import { CONFIG } from '../config';
import Decimal from 'decimal.js';
import { CosmWasmClient } from '@cosmjs/cosmwasm-stargate';

interface FullStateData {
  cap: string;
  contract_addr_human: string;
  denom: string;
  fees_accrued: string;
  lower_swap_limit: string;
  next_swap_id: number;
  paused_since_block_public_api: number;
  paused_since_block_relayer_api: number;
  relay_eon: number;
  reverse_aggregated_allowance: string;
  reverse_aggregated_allowance_approver_cap: string;
  sealed_reverse_swap_id: number;
  supply: string;
  swap_fee: string;
  upper_swap_limit: string;
}

export interface NativeBridgeStatus {
  paused: boolean;
  swapMin: Decimal;
  swapMax: Decimal;
  supply: Decimal;
  cap: Decimal;
  reverseAggLimit: Decimal;
  reverseAggLimitCap: Decimal;
  fee: Decimal;
}

interface JsonNativeBridgeStatus {
  paused: boolean;
  swapMin: string;
  swapMax: string;
  supply: string;
  cap: string;
  reverseAggLimit: string;
  reverseAggLimitCap: string;
  fee: string;
}

export function toNativeStatusJson(
  status: NativeBridgeStatus
): JsonNativeBridgeStatus {
  return {
    paused: status.paused,
    swapMin: status.swapMin.toFixed(0),
    swapMax: status.swapMax.toFixed(0),
    supply: status.supply.toFixed(0),
    cap: status.cap.toFixed(0),
    reverseAggLimit: status.reverseAggLimit.toFixed(0),
    reverseAggLimitCap: status.reverseAggLimitCap.toFixed(0),
    fee: status.fee.toFixed(0),
  };
}

export class NativeBridgeContract {
  readonly contractAddress: string;

  constructor(address: string) {
    this.contractAddress = address;
  }

  async getStatus(): Promise<NativeBridgeStatus> {
    const [contractStatus, height] = await this._queryContract({
      full_state: {},
    });

    const pausedSince = new Decimal(
      contractStatus.paused_since_block_public_api
    );
    const relayerPausedSince = new Decimal(
      contractStatus.paused_since_block_relayer_api
    );

    const isPaused = height.greaterThanOrEqualTo(pausedSince);
    const isRelayerPaused = height.greaterThanOrEqualTo(relayerPausedSince);

    return {
      fee: new Decimal(contractStatus.swap_fee),
      reverseAggLimit: new Decimal(contractStatus.reverse_aggregated_allowance),
      reverseAggLimitCap: new Decimal(
        contractStatus.reverse_aggregated_allowance_approver_cap
      ),
      cap: new Decimal(contractStatus.cap),
      paused: isPaused || isRelayerPaused,
      supply: new Decimal(contractStatus.supply),
      swapMax: new Decimal(contractStatus.upper_swap_limit),
      swapMin: new Decimal(contractStatus.lower_swap_limit),
    };
  }

  private async _queryContract(query: any): Promise<[FullStateData, Decimal]> {
    const client = await CosmWasmClient.connect(CONFIG.fetch.rpcUrl);
    try {
      const result = await client.queryContractSmart(
        this.contractAddress,
        query
      );
      return [result, new Decimal(await client.getHeight())];
    } catch (e) {
      console.log('Contract Query failure', e);
      throw new Error('Failed to query contract');
    }
  }
}
