import React from 'react';

export enum TransferTab {
  ETH_TO_NATIVE,
  NATIVE_TO_ETH,
}

function tabClassNames(currentTab: TransferTab, tab: TransferTab): string {
  let classNames = 'Tab';
  if (currentTab === tab) {
    classNames += ' ActiveTab';
  }
  return classNames;
}

interface TabSelectionProps {
  tab: TransferTab;
  setTab: (tab: TransferTab) => void;
}

export function TabSelection(props: TabSelectionProps) {
  return (
    <div className="TabSelect">
      <button
        className={tabClassNames(props.tab, TransferTab.ETH_TO_NATIVE)}
        onClick={() => props.setTab(TransferTab.ETH_TO_NATIVE)}
      >
        ERC20 to Native
      </button>
      <button
        className={tabClassNames(props.tab, TransferTab.NATIVE_TO_ETH)}
        onClick={() => props.setTab(TransferTab.NATIVE_TO_ETH)}
      >
        Native to ERC20
      </button>
    </div>
  );
}
