import React from 'react';
import agentLogo from '../agent.png';

export function MetamaskLocked() {
  const handleUnlock = async () => {
    // @ts-ignore
    await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
  };

  return (
    <div className="CardContainer">
      <div className="TextContainer">
        <img src={agentLogo} height={150} alt="Agent Logo" />
        <p>
          It appears that Metamask is locked please unlock your metamask an
          connect this application to start using it
        </p>
        <input
          className="SubmitButton"
          type="submit"
          value="Unlock"
          style={{ width: 300 }}
          onClick={handleUnlock}
        />
      </div>
    </div>
  );
}
