import { bech32 } from 'bech32';
import { Decimal } from 'decimal.js';
import { MAX_FET_SUPPLY } from './config';

export enum ValidationState {
  UNKNOWN,
  VALID,
  INVALID,
}

export function validateNativeFetchAddress(value: string): ValidationState {
  if (value === '') {
    return ValidationState.UNKNOWN;
  }

  try {
    const result = bech32.decode(value);

    if (result.prefix === 'fetch') {
      return ValidationState.VALID;
    }
  } catch {}

  return ValidationState.INVALID;
}

export function validateAmount(value: string): ValidationState {
  if (value === '') {
    return ValidationState.UNKNOWN;
  }

  try {
    const maxAmount = new Decimal(MAX_FET_SUPPLY);
    const amount = new Decimal(value);

    if (amount.decimalPlaces() <= 18 && amount.lessThan(maxAmount)) {
      return ValidationState.VALID;
    }
  } catch {}

  return ValidationState.INVALID;
}

export const snooze = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
