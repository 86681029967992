import React from 'react';
import { ValidationState } from '../utils';

interface TextInputProps {
  name: string;
  value?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  invalidText?: string;
  placeholder?: string;
  validation?: ValidationState;
}

export function TextInput(props: TextInputProps) {
  const validationState = props.validation ?? ValidationState.UNKNOWN;

  const isInvalid =
    props.invalidText !== undefined &&
    validationState === ValidationState.INVALID;

  return (
    <>
      <label className="InputLabel">{props.name}</label>
      <input
        className="Input"
        placeholder={props.placeholder ?? props.name}
        value={props.value}
        readOnly={props.readOnly}
        onChange={(e) => {
          if (props.onChange !== undefined) {
            props.onChange(e.target.value);
          }
        }}
      />
      {isInvalid && <p className="FeedbackWarning">{props.invalidText}</p>}
    </>
  );
}
