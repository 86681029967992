import React from 'react';
import agentLogo from '../agent.png';

export function InternalError() {
  return (
    <div className="CardContainer">
      <div className="TextContainer">
        <img src={agentLogo} height={150} alt="Agent Logo" />
        <p>
          The site is currently down for maintenance. We are working hard to
          minimise this disruption but please check back a little later on.
        </p>
      </div>
    </div>
  );
}
