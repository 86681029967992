import TransportWebUSB from '@ledgerhq/hw-transport-webusb';
import CosmosApp, { Response } from 'ledger-cosmos-js';
import sha256 from 'crypto-js/sha256';
import ripemd160 from 'crypto-js/ripemd160';
import CryptoJS from 'crypto-js';
import { bech32 } from 'bech32';
import { signatureImport } from 'secp256k1';
import {
  OfflineAminoSigner,
  StdSignDoc,
  AminoSignResponse,
  serializeSignDoc,
} from '@cosmjs/amino';
import { AccountData } from '@cosmjs/proto-signing';
import { encodeSecp256k1Signature } from '@cosmjs/amino';
import { fromUtf8, toHex } from '@cosmjs/encoding';

const LEDGER_TIMEOUT = 10000;
const HD_PATH = [44, 118, 0, 0, 0];
export const BECH32_PREFIX = 'fetch';
const REQUIRED_COSMOS_APP_VERSION = '2.0.0';
const TIMEOUT_MESSAGE = 'Connection timed out. Please try again.';
const REJECTION_MESSAGE = 'User rejected the transaction';

function bech32ify(address: Uint8Array): string {
  const words = bech32.toWords(address);
  return bech32.encode(BECH32_PREFIX, words);
}

function createCosmosAddress(publicKey: Uint8Array): string {
  const message = CryptoJS.enc.Hex.parse(toHex(publicKey));
  const hash = ripemd160(sha256(message)).toString();
  const address = Buffer.from(hash, `hex`);
  return bech32ify(address);
}

export class Ledger implements OfflineAminoSigner {
  private cosmosApp: CosmosApp;

  static async connect(): Promise<Ledger> {
    const transport = await TransportWebUSB.create(LEDGER_TIMEOUT);
    const cosmosApp = new CosmosApp(transport);

    return new Ledger(cosmosApp);
  }

  constructor(cosmosApp: CosmosApp) {
    this.cosmosApp = cosmosApp;
  }

  async isReady(): Promise<boolean> {
    const response = await this.cosmosApp.publicKey(HD_PATH);
    Ledger.ensureNoErrors(response);

    return true;
  }

  async getAddress(): Promise<[string, Uint8Array]> {
    const publicKey = await this.getPublicKey();
    return [createCosmosAddress(publicKey), publicKey];
  }

  private async getPublicKey(): Promise<Uint8Array> {
    const response = await this.cosmosApp.publicKey(HD_PATH);
    Ledger.ensureNoErrors(response);

    return response.compressed_pk;
  }

  private static ensureNoErrors(response: Response) {
    switch (response.error_message) {
      case `U2F: Timeout`:
        throw new Error(TIMEOUT_MESSAGE);
      case `Cosmos app does not seem to be open`:
        throw new Error(`Cosmos app is not open`);
      case `Command not allowed`:
        throw new Error(`Transaction rejected`);
      case `Transaction rejected`:
        throw new Error(REJECTION_MESSAGE);
      case `Unknown error code`:
        throw new Error(`Ledger's screensaver mode is on`);
      case `Instruction not supported`:
        throw new Error(
          `Your Cosmos Ledger App is not up to date. ` +
            `Please update to version ${REQUIRED_COSMOS_APP_VERSION}.`
        );
      case `No errors`:
        // do nothing
        break;
      default:
        throw new Error(response.error_message);
    }
  }

  private async sign(signMessage: string): Promise<Uint8Array> {
    const response = await this.cosmosApp.sign(HD_PATH, signMessage);
    Ledger.ensureNoErrors(response);
    // we have to parse the signature from Ledger as it's in DER format
    return signatureImport(response.signature);
  }

  // getAccounts implements OfflineAminoSigner::getAccounts()
  public async getAccounts(): Promise<AccountData[]> {
    const [address, publicKey] = await this.getAddress();
    return [
      {
        algo: 'secp256k1',
        pubkey: publicKey,
        address: address,
      },
    ];
  }

  // signAmino implements OfflineAminoSigner::signAmino()
  public async signAmino(
    signerAddress: string,
    signDoc: StdSignDoc
  ): Promise<AminoSignResponse> {
    const [address, publicKey] = await this.getAddress();
    if (signerAddress !== address) {
      throw new Error(`Address ${signerAddress} not found in ledger`);
    }
    const signature = await this.sign(fromUtf8(serializeSignDoc(signDoc)));
    const stdSignature = encodeSecp256k1Signature(publicKey, signature);
    return {
      signed: signDoc,
      signature: stdSignature,
    };
  }
}
