import React from 'react';

import agentLogo from '../agent.png';

export function MetaMaskUnavailable() {
  return (
    <div className="CardContainer">
      <div className="TextContainer">
        <img src={agentLogo} height={150} alt="Agent Logo" />
        <p>
          It appears that you do not have Metamask installed on your system.
          Metamask is required by this application to start the migration
          procedure
        </p>
        <p>Please ensure that Metamask is installed and try again</p>
      </div>
    </div>
  );
}
