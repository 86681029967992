const MAINNET_CHAIN_ID = 1;
const KOVAN_CHAIN_ID = 42;

export enum Network {
  MAINNET,
  KOVAN,
  UNKNOWN,
}

export function parseNetwork(value: string): Network {
  const chainId = parseInt(value);
  if (chainId === MAINNET_CHAIN_ID) {
    return Network.MAINNET;
  } else if (chainId === KOVAN_CHAIN_ID) {
    return Network.KOVAN;
  } else {
    return Network.UNKNOWN;
  }
}

export function formatNetwork(net: Network): string {
  switch (net) {
    case Network.MAINNET:
      return 'Mainnet';
    case Network.KOVAN:
      return 'Kovan';
    case Network.UNKNOWN:
      return 'Unknown';
  }
}
